import React, { useCallback } from 'react';
import Link from 'next/link';
import classnames from 'classnames';
import { useRouter } from 'next/router';

import Image from 'components/Image/Image';
import FeedbackButton from 'components/FeedbackButton/FeedbackButton';
import LayoutContainer from 'components/LayoutContainer/LayoutContainer';
import LargeIconButton from 'components/LargeIconButton/LargeIconButton';
import { ROUTES } from 'settings/routingConstants';
import { commonCls, tabletCls, mobileCls } from 'settings/uiKitClasses';
import { useAppContext } from 'models/AppContext/AppContext';
import { AcToggleMenuModal } from 'models/AppContext/AppActions';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MenuIcon from '@material-ui/icons/Menu';
import styles from './Header.module.scss';

export const HeaderContent: React.FC<{ title: React.ReactNode; description: React.ReactNode }> = ({
    title,
    description,
}) => {
    const [, dispatch] = useAppContext();
    const openMenu = useCallback(() => dispatch(AcToggleMenuModal(true)), [dispatch]);
    return (
        <>
            <header className={styles.header}>
                <div className={styles.bgWrap}>
                    <Image
                        src="/images/ui/headerBg_1x.jpg"
                        srcSet="/images/ui/headerBg_2x.jpg 2x"
                        alt="map background"
                        layout="fill"
                        objectFit="cover"
                        priority
                    >
                        <source
                            srcSet="/images/ui/headerBg_1x.webp 1x, /images/ui/headerBg_2x.webp 2x"
                            type="image/webp"
                        />
                    </Image>
                </div>
                <LayoutContainer rootClassName={styles.navContainer}>
                    <nav className={styles.nav}>
                        <Link href={ROUTES.MAIN}>
                            <a className={styles.mainLink}>
                                <Image
                                    className={styles.mainLogo}
                                    src="/images/ui/codbts.svg"
                                    alt="main logo"
                                    width={317}
                                    height={49}
                                    priority
                                />
                            </a>
                        </Link>
                        <ul className={classnames(styles.navList, commonCls.Button)}>
                            <li>
                                <Link href={ROUTES.ABOUT}>
                                    <a className={styles.navLink}>
                                        <span className={styles.navLinkNum}>01</span>
                                        <span className={styles.navLinkLabel}>О нас</span>
                                        <ArrowForwardIcon fontSize="small" className={styles.navLinkLabelIcon} />
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <Link href={ROUTES.SOLUTION}>
                                    <a className={styles.navLink}>
                                        <span className={styles.navLinkNum}>02</span>
                                        <span className={styles.navLinkLabel}>Наше решение</span>
                                        <ArrowForwardIcon fontSize="small" className={styles.navLinkLabelIcon} />
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <Link href={ROUTES.NEWS}>
                                    <a className={styles.navLink}>
                                        <span className={styles.navLinkNum}>03</span>
                                        <span className={styles.navLinkLabel}>Новости</span>
                                        <ArrowForwardIcon fontSize="small" className={styles.navLinkLabelIcon} />
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <Link href={ROUTES.EPR}>
                                    <a className={styles.navLink}>
                                        <span className={styles.navLinkNum}>04</span>
                                        <span className={styles.navLinkLabel}>ЭПР в Иннополисе</span>
                                        <ArrowForwardIcon fontSize="small" className={styles.navLinkLabelIcon} />
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <FeedbackButton className={styles.navLink}>
                                    <span className={styles.navLinkNum}>05</span>
                                    <span className={styles.navLinkLabel}>Обратная связь</span>
                                    <ArrowForwardIcon fontSize="small" className={styles.navLinkLabelIcon} />
                                </FeedbackButton>
                            </li>
                        </ul>
                        <LargeIconButton className={styles.menuIcon} onClick={openMenu}>
                            <MenuIcon />
                        </LargeIconButton>
                    </nav>
                </LayoutContainer>
                <LayoutContainer rootClassName={styles.contentContainer}>
                    <article className={styles.headerContent}>
                        <h2
                            className={classnames(
                                styles.headerTitle,
                                commonCls.Headline2,
                                tabletCls.Headline3,
                                mobileCls.Headline5,
                            )}
                        >
                            {title}
                        </h2>
                        <p className={classnames(styles.headerDescription, commonCls.Headline6, mobileCls.Subtitle1)}>
                            {description}
                        </p>
                    </article>
                </LayoutContainer>
            </header>
        </>
    );
};

const Header: React.FC = () => {
    const { pathname } = useRouter();
    return (() => {
        switch (pathname) {
            case ROUTES.MAIN:
                return (
                    <HeaderContent
                        title={
                            <>
                                Мы участвуем в&nbsp;создании безопасного и&nbsp;доступного воздушного пространства
                                для&nbsp;всех и&nbsp;каждого
                            </>
                        }
                        description={
                            <>
                                В&nbsp;сотрудничестве с&nbsp;регулирующими органами, органами власти, предприятиями,
                                поставщиками беспилотных решений и&nbsp;пилотами мы&nbsp;разрабатываем цифровую
                                инфраструктуру, стандарты и&nbsp;услуги для&nbsp;безопасной эксплуатации беспилотных
                                воздушных судов
                            </>
                        }
                    />
                );
            case ROUTES.EPR:
                return (
                    <HeaderContent
                        title="ЭПР в Иннополисе"
                        description={
                            <>
                                Цифровая платформа AVTM будет способствовать выбору безопасных маршрутов, осуществлять
                                мониторинг полётов и помогать в оформлении документов, необходимых для полёта.
                            </>
                        }
                    />
                );
            case ROUTES.PLATFORM:
                return (
                    <HeaderContent
                        title="Система диспетчеризации беспилотного воздушного пространства avtm.center"
                        description={
                            <>
                                Полный набор решений для безопасной эксплуатации БВС в контролируемом воздушном
                                пространстве
                            </>
                        }
                    />
                );
            case ROUTES.SOLUTION:
                return (
                    <HeaderContent
                        title={<>Полноформатная UTM&nbsp;система</>}
                        description={
                            <>
                                Платформа AVTM.MAP предоставляет полный набор решений для&nbsp;эффективной
                                и&nbsp;безопасной эксплуатации БВС в&nbsp;контролируемом воздушном пространстве
                            </>
                        }
                    />
                );
            default:
                return null;
        }
    })();
};

export default Header;
