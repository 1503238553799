import React from 'react';
import classnames from 'classnames';
import Button, { ButtonProps } from '@material-ui/core/Button';

import styles from './LargeIconButton.module.scss';

export const LargeIconButton: React.FC<ButtonProps> = props => {
    return (
        <Button color="primary" {...props} className={classnames(styles.root, props.className)}>
            {props.children}
        </Button>
    );
};

export default LargeIconButton;
