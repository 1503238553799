export const ANCHORS = {
    WHERE: 'where-we-are',
};

export const ROUTES = {
    MAIN: '/',
    ABOUT: '/about',
    EPR: '/innopolis-special-regime',
    PLATFORM: '/platform',
    SOLUTION: '/solution',
    SERVICES: '/services',
    NEWS: '/news',
    TO_NEWS: (id: string) => `/news/${id}`,

    ADDRESS: `/about#${ANCHORS.WHERE}`,
};

export const OUTER_LINKS = {
    RussianPost: 'https://pochta.ru',
    Rostec: 'https://rostec.ru',
    Tiber: 'https://www.tiber.su',
    IU: 'https://innopolis.university',
    CTL: 'https://dtla.ru/',
    Aeronext: 'https://aeronext.aero/',
    avtmTeamPositionOnMap: 'https://map.avtm.center/coords/?c=48.75157898476846,55.752261471307264,17&q=landing',
    regDemo: 'https://demo-cod.avtm.center/acs/uav-accounting-list?onboarding=true&q=landing',
    avtm: 'https://map.avtm.center?q=landing',
};
