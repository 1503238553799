// все ui kit typography класс-компоненты с префиксами для разных типов устройств
export enum commonCls {
    Headline1 = 'Headline1',
    Headline2 = 'Headline2',
    Headline3 = 'Headline3',
    Headline4 = 'Headline4',
    Headline5 = 'Headline5',
    Headline6 = 'Headline6',
    Headline7 = 'Headline7',
    Subtitle1 = 'Subtitle1',
    Subtitle2 = 'Subtitle2',
    Body1 = 'Body1',
    Body2 = 'Body2',
    Body3 = 'Body3',
    Button = 'Button',
    Button1 = 'Button1',
    Button2 = 'Button2',
    ButtonSmall = 'ButtonSmall',
    Caption = 'Caption',
    Overline = 'Overline',
}

export enum tabletCls {
    Headline1 = 'TabletHeadline1',
    Headline2 = 'TabletHeadline2',
    Headline3 = 'TabletHeadline3',
    Headline4 = 'TabletHeadline4',
    Headline5 = 'TabletHeadline5',
    Headline6 = 'TabletHeadline6',
    Headline7 = 'TabletHeadline7',
    Subtitle1 = 'TabletSubtitle1',
    Subtitle2 = 'TabletSubtitle2',
    Body1 = 'TabletBody1',
    Body2 = 'TabletBody2',
    Body3 = 'TabletBody3',
    Button = 'TabletButton',
    Button1 = 'TabletButton1',
    Button2 = 'TabletButton2',
    ButtonSmall = 'TabletButtonSmall',
    Caption = 'TabletCaption',
    Overline = 'TabletOverline',
}

export enum mobileCls {
    Headline1 = 'MobileHeadline1',
    Headline2 = 'MobileHeadline2',
    Headline3 = 'MobileHeadline3',
    Headline4 = 'MobileHeadline4',
    Headline5 = 'MobileHeadline5',
    Headline6 = 'MobileHeadline6',
    Headline7 = 'MobileHeadline7',
    Subtitle1 = 'MobileSubtitle1',
    Subtitle2 = 'MobileSubtitle2',
    Body1 = 'MobileBody1',
    Body2 = 'MobileBody2',
    Body3 = 'MobileBody3',
    Button = 'MobileButton',
    Button1 = 'MobileButton1',
    Button2 = 'MobileButton2',
    ButtonSmall = 'MobileButtonSmall',
    Caption = 'MobileCaption',
    Overline = 'MobileOverline',
}
