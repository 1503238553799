import React, { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './LayoutContainer.module.scss';

export const LayoutContainer: React.FC<{
    rootClassName?: string;
    shiftedRight?: boolean;
    outerContainerClassName?: string;
    innerContainerClassName?: string;
    grayBg?: boolean;
    bgContent?: React.ReactNode;
    children?: ReactNode;
}> = ({
    children,
    rootClassName,
    outerContainerClassName,
    innerContainerClassName,
    bgContent = null,
    grayBg = false,
    shiftedRight = false,
}) => {
    return (
        <div className={classnames(styles.root, rootClassName)}>
            <div
                className={classnames(styles.outerContainer, outerContainerClassName, {
                    [styles.shiftedRight]: shiftedRight,
                })}
            >
                <div className={classnames(styles.innerContainer, innerContainerClassName)}>
                    {(grayBg || bgContent) && (
                        <div className={classnames(styles.bgContainer)}>
                            <div className={styles.bgContent}>{bgContent}</div>
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
};

export default LayoutContainer;
