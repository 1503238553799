import React, { useCallback, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import LargeIconButton from 'components/LargeIconButton/LargeIconButton';
import Modal from 'components/Modal/Modal';
import { useAppContext } from 'models/AppContext/AppContext';
import { AcToggleMenuModal } from 'models/AppContext/AppActions';
import FeedbackButton from 'components/FeedbackButton/FeedbackButton';
import { ROUTES } from 'settings/routingConstants';

import CloseIcon from '@material-ui/icons/Close';
import styles from './Menu.module.scss';

export const Menu: React.FC = () => {
    const router = useRouter();
    const [context, dispatch] = useAppContext();
    const closeModal = useCallback(() => dispatch(AcToggleMenuModal(false)), [dispatch]);

    useEffect(() => {
        return () => closeModal();
    }, [router.pathname, closeModal]);

    const onClick = useCallback(
        (route: string) => {
            if (route === router.pathname) {
                closeModal();
            }
        },
        [router, closeModal],
    );

    return (
        <Modal open={context.menuIsOpened} onClose={closeModal}>
            <div className={styles.root}>
                <LargeIconButton onClick={closeModal}>
                    <CloseIcon />
                </LargeIconButton>
                <nav className={styles.nav}>
                    <ul className={styles.navList}>
                        <li onClick={() => onClick(ROUTES.ABOUT)}>
                            <Link href={ROUTES.ABOUT}>
                                <a className={styles.navLink}>О нас</a>
                            </Link>
                        </li>
                        <li onClick={() => onClick(ROUTES.SOLUTION)}>
                            <Link href={ROUTES.SOLUTION}>
                                <a className={styles.navLink}>Наше решение</a>
                            </Link>
                        </li>
                        <li onClick={() => onClick(ROUTES.NEWS)}>
                            <Link href={ROUTES.NEWS}>
                                <a className={styles.navLink}>Новости</a>
                            </Link>
                        </li>
                        <li onClick={() => onClick(ROUTES.EPR)}>
                            <Link href={ROUTES.EPR}>
                                <a className={styles.navLink}>ЭПР в Иннополисе</a>
                            </Link>
                        </li>
                        <li onClick={closeModal}>
                            <FeedbackButton className={styles.navLink}>Обратная связь</FeedbackButton>
                        </li>
                    </ul>
                </nav>
            </div>
        </Modal>
    );
};

export default Menu;
