import React, { useCallback } from 'react';
import dynamic from 'next/dynamic';

const FeedbackForm = dynamic(() => import('./FeedbackForm'));
import LargeIconButton from 'components/LargeIconButton/LargeIconButton';
import Modal from 'components/Modal/Modal';
import { useAppContext } from 'models/AppContext/AppContext';
import { AcToggleFeedbackModal } from 'models/AppContext/AppActions';

import CloseIcon from '@material-ui/icons/Close';
import styles from './Feedback.module.scss';

export const Feedback: React.FC = () => {
    const [context, dispatch] = useAppContext();
    const closeModal = useCallback(() => dispatch(AcToggleFeedbackModal(false)), [dispatch]);

    return (
        <Modal open={context.feedbackIsOpened} onClose={closeModal}>
            <div className={styles.root}>
                <LargeIconButton onClick={closeModal}>
                    <CloseIcon />
                </LargeIconButton>
                <FeedbackForm onSubmit={closeModal} className={styles.form} />
            </div>
        </Modal>
    );
};

export default Feedback;
