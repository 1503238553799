import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import { useAppContext } from 'models/AppContext/AppContext';

import styles from './CursorAim.module.scss';
import { checkMobile } from 'services/Helpers/Platform/Platform';

export const CursorAim: React.FC = () => {
    const [context] = useAppContext();
    const [cursorEl, setCursorEl] = useState<HTMLDivElement | null>(null);
    const [headerSighting, setHeaderSighting] = useState(false);
    const [clickableSighting, setClickableSighting] = useState(false);

    useEffect(() => {
        if (checkMobile() || context.feedbackIsOpened || context.menuIsOpened) {
            return () => {};
        }

        const moveHandler = (e: MouseEvent) => {
            const { clientX: x, clientY: y } = e;
            if (cursorEl) {
                cursorEl.setAttribute('style', `transform: translate(${x}px, ${y}px); display: block;`);
            }
            const el = e.target as HTMLElement | null;
            if (!el) {
                return;
            }

            setClickableSighting(
                el.matches(
                    'a,a *, button, button *, .MuiInputBase-root, .MuiInputBase-root *, .MuiButtonBase-root, .MuiButtonBase-root *, [data-interactive], [data-interactive] *',
                ),
            );
            setHeaderSighting(el.matches('header, header *'));
        };

        window.addEventListener('mousemove', moveHandler);

        return () => window.removeEventListener('mousemove', moveHandler);
    }, [context.feedbackIsOpened, context.menuIsOpened, cursorEl]);

    return (
        <aside
            className={classnames(styles.container, {
                [styles.whiteMode]: headerSighting,
                [styles.largeMode]: clickableSighting,
            })}
        >
            <div className={styles.center} ref={setCursorEl}>
                <div className={styles.circle} />
                <div className={styles.horizontalLine} />
                <div className={styles.verticalLine} />
            </div>
        </aside>
    );
};

export default CursorAim;
