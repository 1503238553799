import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import LargeIconButton from 'components/LargeIconButton/LargeIconButton';
import { useAppContext } from 'models/AppContext/AppContext';
import { AcRemoveMessage } from 'models/AppContext/AppActions';
import { IMessage } from 'models/AppContext/Messages';
import { commonCls, tabletCls } from 'settings/uiKitClasses';

import CloseIcon from '@material-ui/icons/Close';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import styles from './Messages.module.scss';

export const Messages: React.FC = () => {
    const [context] = useAppContext();

    return (
        <aside className={styles.container}>
            <ul>
                {context.messages.map(messageData => (
                    <Message {...messageData} key={messageData.timestamp} />
                ))}
            </ul>
        </aside>
    );
};

const displayDuration = 3000;
const hideDuration = 500;

const Message: React.FC<IMessage> = ({ title, timestamp, error }) => {
    const [, dispatch] = useAppContext();
    const [hidden, setHidden] = useState(true);

    // добавляем класс styles.shown после рендера, чтобы отыграть анимацию
    useEffect(() => {
        setHidden(false);
    }, [setHidden]);

    const hide = useCallback(() => setHidden(true), [setHidden]);

    // если элемент показан - запускаем таймер на скрытие
    useEffect(() => {
        if (hidden) {
            return () => {};
        }

        const toId = setTimeout(hide, displayDuration);
        return () => {
            clearTimeout(toId);
        };
    }, [hide, hidden]);

    // если элемент скрыт - запускаем таймер на удаление (после анимации скрытия)
    useEffect(() => {
        if (!hidden) {
            return () => {};
        }

        const toId = setTimeout(() => dispatch(AcRemoveMessage(timestamp)), hideDuration);
        return () => {
            clearTimeout(toId);
        };
    }, [hidden, timestamp, dispatch]);

    const IconComponent = useMemo(() => (error ? ThumbDownOutlinedIcon : ThumbUpOutlinedIcon), [error]);

    return (
        <li className={classnames(styles.message, { [styles.error]: error, [styles.shown]: !hidden })}>
            <IconComponent className={styles.icon} />
            <span className={classnames(styles.title, commonCls.Headline7, tabletCls.Caption)}>{title}</span>
            <LargeIconButton color="inherit" onClick={hide}>
                <CloseIcon />
            </LargeIconButton>
        </li>
    );
};

export default Messages;
