import ym from 'react-yandex-metrika';

export default class YandexMetrikaEvents {
    // Открытие модального окна "Обратная связь"
    static dispatchFeedbackWasOpened() {
        YandexMetrikaEvents.dispatchEvent('open_feedback');
    }

    // Переход к REG-Demo
    static dispatchRegDemoClick() {
        YandexMetrikaEvents.dispatchEvent('reg_demo_click');
    }

    private static dispatchEvent(target: string) {
        ym('reachGoal', target);
    }
}
