import React from 'react';
import classnames from 'classnames';

import styles from './Image.module.scss';

interface IImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    width?: number;
    height?: number;
    layout?: 'fill';
    objectFit?: 'cover';
    imgClassname?: string;
    priority?: boolean;
    autoWidth?: boolean;
}

export const Image: React.FC<IImageProps> = ({
    className,
    imgClassname,
    layout,
    width,
    height,
    objectFit,
    priority,
    children,
    autoWidth,
    ...props
}) => {
    return (
        <div
            className={classnames(className, styles.root, {
                [styles._autoWidth]: autoWidth,
                [styles.cover]: objectFit === 'cover',
            })}
        >
            {(width || height) && (
                <div className={styles.spacer}>
                    <img
                        className={styles.spacerImg}
                        alt="placeholder"
                        aria-hidden="true"
                        role="presentation"
                        src={`data:image/svg+xml;charset=UTF-8,%3csvg width='${width}' height='${height}' viewBox='0 0 ${width} ${height}' fill='none' xmlns='http://www.w3.org/2000/svg' /%3e`}
                    />
                </div>
            )}
            <picture className={styles.picture}>
                {children}
                <img
                    decoding={priority ? undefined : 'async'}
                    alt={props.alt}
                    {...props}
                    className={classnames(imgClassname, styles.img, {
                        [styles.cover]: objectFit === 'cover',
                    })}
                    sizes={layout === 'fill' ? '100vw' : undefined}
                />
            </picture>
        </div>
    );
};

export default Image;
