import React, { ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';

import styles from './Modal.module.scss';

export const Modal: React.FC<{ onClose?: () => void; open: boolean; children?: ReactNode }> = props => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            BackdropComponent={() => <div className={styles.backDrop} onClick={props.onClose} />}
            PaperComponent={() => <div className={styles.paperContent}>{props.children}</div>}
            classes={{ scrollPaper: styles.paper }}
            transitionDuration={{ exit: 0 }}
        />
    );
};

export default Modal;
