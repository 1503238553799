import React, { ReactNode, useCallback } from 'react';
import classnames from 'classnames';

import { useAppContext } from 'models/AppContext/AppContext';
import { AcToggleFeedbackModal } from 'models/AppContext/AppActions';
import YandexMetrikaEvents from 'services/YandexMetrika/YandexMetrikaEvents';

import styles from './FeedbackButton.module.scss';

export const FeedbackButton: React.FC<{ className?: string; children?: ReactNode }> = ({ className, children }) => {
    const [, dispatch] = useAppContext();
    const openFeedback = useCallback(() => {
        YandexMetrikaEvents.dispatchFeedbackWasOpened();
        dispatch(AcToggleFeedbackModal(true));
    }, [dispatch]);

    return (
        <button onClick={openFeedback} className={classnames(styles.root, className)}>
            {children}
        </button>
    );
};

export default FeedbackButton;
