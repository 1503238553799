import React from 'react';

import { InferValueTypes } from 'models/Helpers/Helpers';
import * as actions from './AppActions';
import { IMessage } from './Messages';

export default interface IAppContext {
    feedbackIsOpened: boolean;
    menuIsOpened: boolean;
    messages: IMessage[];
}

export const AppInitialState: IAppContext = {
    feedbackIsOpened: false,
    menuIsOpened: false,
    messages: [],
};

type AppActions = ReturnType<InferValueTypes<typeof actions>>;

export const AppContextReducer = (state: IAppContext, action: AppActions): IAppContext => {
    switch (action.type) {
        case 'TOGGLE_FEEDBACK_MODAL':
            return {
                ...state,
                feedbackIsOpened: action.payload,
            };
        case 'TOGGLE_MENU_MODAL':
            return {
                ...state,
                menuIsOpened: action.payload,
            };
        case 'ADD_MESSAGE':
            return {
                ...state,
                messages: [...state.messages, action.payload],
            };
        case 'REMOVE_MESSAGE':
            return {
                ...state,
                messages: state.messages.filter(({ timestamp }) => timestamp !== action.payload),
            };
        default:
            return state;
    }
};

export const AppContext = React.createContext({} as IAppContext);
export const AppDispatch = React.createContext<React.Dispatch<AppActions>>(() => {});

export const useAppContext = (): [IAppContext, React.Dispatch<AppActions>] => {
    return [React.useContext(AppContext), React.useContext(AppDispatch)];
};
