import { IMessage } from './Messages';

export const AcToggleFeedbackModal = (payload: boolean) =>
    ({
        payload,
        type: 'TOGGLE_FEEDBACK_MODAL',
    } as const);

export const AcToggleMenuModal = (payload: boolean) =>
    ({
        payload,
        type: 'TOGGLE_MENU_MODAL',
    } as const);

export const AcAddMessage = (title: string, error = false) =>
    ({
        payload: { title, error, timestamp: Date.now() } as IMessage,
        type: 'ADD_MESSAGE',
    } as const);

export const AcRemoveMessage = (timestamp: number) =>
    ({
        payload: timestamp,
        type: 'REMOVE_MESSAGE',
    } as const);
