import React from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { YMInitializer } from 'react-yandex-metrika';

import Header from 'components/Header/Header';
import CursorAim from 'components/CursorAim/CursorAim';
import Feedback from 'components/Feedback/Feedback';
import Menu from 'components/Menu/Menu';
import Messages from 'components/Messages/Messages';
import { AppContextReducer, AppInitialState, AppContext, AppDispatch } from 'models/AppContext/AppContext';

import 'focus-visible';
import 'styles/globals.scss';
import '../../public/fonts/NotoSans/fonts.css';

const theme = createTheme({
    typography: {
        fontFamily: "'Noto Sans', Roboto, sans-serif",
    },
    shape: {
        borderRadius: 8,
    },
    palette: {
        primary: {
            main: '#4d94ff', // Primary70Clear
            '700': '#0066ff', // Primary
        },
        info: {
            main: '#93a2b3', // Grey50Clear
            '700': '#687d95', // Grey70Clear
        },
        success: {
            main: '#269100', // DarkGreen
        },
        warning: {
            main: '#d98f00', // DarkYellow
        },
        error: {
            main: '#ff4da2', // Red70Clear
        },
        text: {
            primary: '#274568', // Grey
            disabled: '#bec7d2', // Grey30Clear
        },
        action: {
            active: '#274568', // Grey
            disabled: '#93a2b3', // Grey50Clear
            disabledBackground: '#bec7d2', // Grey30Clear
        },
    },
    overrides: {
        MuiInputBase: {
            input: {
                '::-webkit-outer-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                },
                '::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0,
                },
                ':is(input[type=number])': {
                    MozAppearance: 'textfield',
                },
                '::-ms-reveal': {
                    display: 'none',
                },
                '::-ms-clear': {
                    display: 'none',
                },
                '::-webkit-file-upload-button': {
                    display: 'none',
                },
                '::file-selector-button': {
                    display: 'none',
                },
                ':is(input[type="time"])': {
                    appearance: 'none',
                },
                '::-webkit-inner-spin-button, ::-webkit-outer-spin-button, ::-webkit-clear-button, ::-webkit-datetime-edit-ampm-field, ::-webkit-calendar-picker-indicator':
                    {
                        background: 'none',
                        display: 'none',
                        appearance: 'none',
                        margin: '-10px',
                    },
            },
        },
        MuiTextField: {
            root: {
                display: 'flex',
            },
        },
        MuiButton: {
            colorInherit: {
                color: 'var(--button-color, inherit)',
            },
            sizeLarge: {
                borderRadius: '0.5rem',
                fontSize: '0.875rem',
                fontWeight: 'bold',
                height: '2.5rem',
                minWidth: '2.5rem',
                padding: '0 1rem',
                '> span > :nth-of-type(1), > svg': {
                    fontSize: '1.5rem',
                },
            },
            contained: {
                ':is(.Mui-disabled)': {
                    color: '#ffffff',
                },
            },
        },
    },
});

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
    const [state, dispatch] = React.useReducer(AppContextReducer, AppInitialState);

    return (
        <>
            <Head>
                <meta property="og:title" content="avtm.center" />
                <link rel="icon" href="/favicon.ico" />
                <meta
                    name="viewport"
                    content="width=device-width,minimum-scale=1.0,maximum-scale=1.0,initial-scale=1.0"
                />
                <meta name="theme-color" content={theme.palette.primary.main} />
                <title>AVTM.CENTER — Безопасное и доступное воздушное пространство для всех и каждого</title>
                <meta
                    name="description"
                    content="Цифровая платформа для управления флотом БВС — AVTM. Сотрудничаем с лидерами отрасли и регуляторами для создания более безопасного и доступного воздушного пространства для беспилотников. Карта беспилотных зон для дронов."
                />
                <meta
                    name="keywords"
                    content="ЦОД БТС, UTM, БВС, БАС, Единое воздушное пространство, Несегрегированное воздушное пространство, Сегрегированное воздушное пространство, Беспилотное воздушное судно, Беспилотная авиационная система, Регулирование беспилотников, Диспетчеризация беспилотников"
                />
                {/* TODO: подключать шрифты по необходиомости / без preload */}
                {/* <link href="/fonts/NotoSans/NotoSans-Thin.ttf" rel="preload" as="font" crossOrigin="" />
                <link href="/fonts/NotoSans/NotoSans-ThinItalic.ttf" rel="preload" as="font" crossOrigin="" />
                <link href="/fonts/NotoSans/NotoSans-ExtraLight.ttf" rel="preload" as="font" crossOrigin="" />
                <link href="/fonts/NotoSans/NotoSans-ExtraLightItalic.ttf" rel="preload" as="font" crossOrigin="" />
                <link href="/fonts/NotoSans/NotoSans-Light.ttf" rel="preload" as="font" crossOrigin="" />
                <link href="/fonts/NotoSans/NotoSans-LightItalic.ttf" rel="preload" as="font" crossOrigin="" /> */}
                <link href="/fonts/NotoSans/NotoSans-Regular.ttf" rel="preload" as="font" crossOrigin="" />
                {/* <link href="/fonts/NotoSans/NotoSans-Italic.ttf" rel="preload" as="font" crossOrigin="" />
                <link href="/fonts/NotoSans/NotoSans-Medium.ttf" rel="preload" as="font" crossOrigin="" />
                <link href="/fonts/NotoSans/NotoSans-MediumItalic.ttf" rel="preload" as="font" crossOrigin="" /> */}
                <link href="/fonts/NotoSans/NotoSans-SemiBold.ttf" rel="preload" as="font" crossOrigin="" />
                {/* <link href="/fonts/NotoSans/NotoSans-SemiBoldItalic.ttf" rel="preload" as="font" crossOrigin="" /> */}
                <link href="/fonts/NotoSans/NotoSans-Bold.ttf" rel="preload" as="font" crossOrigin="" />
                {/* <link href="/fonts/NotoSans/NotoSans-BoldItalic.ttf" rel="preload" as="font" crossOrigin="" /> */}
                <link href="/fonts/NotoSans/NotoSans-ExtraBold.ttf" rel="preload" as="font" crossOrigin="" />
                {/* <link href="/fonts/NotoSans/NotoSans-ExtraBoldItalic.ttf" rel="preload" as="font" crossOrigin="" /> */}
                <link href="/fonts/NotoSans/NotoSans-Black.ttf" rel="preload" as="font" crossOrigin="" />
                {/* <link href="/fonts/NotoSans/NotoSans-BlackItalic.ttf" rel="preload" as="font" crossOrigin="" /> */}
            </Head>
            <AppContext.Provider value={state}>
                <AppDispatch.Provider value={dispatch}>
                    <ThemeProvider theme={theme}>
                        <Header />
                        <Component {...pageProps} />
                        <Menu />
                        <Feedback />
                        <Messages />
                        <CursorAim />
                        <YMInitializer
                            accounts={[90214592]}
                            options={{
                                clickmap: true,
                                trackLinks: true,
                                accurateTrackBounce: true,
                                webvisor: true,
                            }}
                        />
                    </ThemeProvider>
                </AppDispatch.Provider>
            </AppContext.Provider>
        </>
    );
};
export default MyApp;
